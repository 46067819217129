declare const window: any;

type UtilsType = {
    config: any;
    apiUrl: string;
    lang: string | null;
    [x: string]: any;

    // function types
    getDate: (date?: string, withTime?: boolean, isUTC?: boolean) => string;
    getStatusColor: (status: string) => string;
    logEvent: (eventType: string, data: any) => Promise<boolean>;
    rest: any;
    fetch: (path: string, returnOnlyData?: boolean) => Promise<any>;
    post: (url: string, data: any, customHeaders?: any) => Promise<any>;
    upload: (url: string, data: any) => Promise<any>;
    useTranslate: (key: string) => string;
    useLoadLangs: () => void;
    useTranslateEnum: (key: string) => string;
    generateUuid: () => string;
    generateClassMap: (parentClass: string, map: object) => any;
    getApiImgPath: (path: string, size?: string) => string;
    formatPrice: (price: number) => string;
    parseUrlToFilters: (url?: string) => any; // fixme: filters type
    convertFiltersToUrl: (filters: any) => string; // fixme: filters type
    doWithTimeout: (callback: () => any, delay: number, timeoutRef?: any) => void; //
};


class Utils {
    [x: string]: any;
    // utils functions are defined here in ./utils and dynamically added to Utils class
    // loaded via ./exportConfig.ts - like sAction in coripo

    constructor() {
        // constructor
        this.config = window?.config?.app;
        this.apiUrl = this.config?.api;
        this.lang = null;
    }
}

// @ts-ignore
const utils: UtilsType = new Utils();

import * as fn from './exportConfig';

const arrName = Object.keys(fn);
const arr = arrName.map((key) => fn[key]);
arr.forEach(function(func, key) {
    Utils.prototype[arrName[key]] = func;
});

export default utils;
