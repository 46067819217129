export default async function doWithTimeout(
    callback: () => any,
    delay: number,
    timeoutRef?: any, // to cancel concurent callbacks, pass timeoutRef
) {
    if (timeoutRef === undefined || timeoutRef === null) {
        setTimeout(() => {
            callback();
        }, delay);
    } else {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        timeoutRef.current = setTimeout(() => {
            callback();
            timeoutRef.current = null;
        }, delay);
    }
}
