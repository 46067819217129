import {api} from '@/api';
import utils from 'utils';

import {useNavigate} from 'react-router-dom';
import {useQueryClient} from '@tanstack/react-query';

export const MainMenu = (props: {
    onClick: () => void,
}) => {
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    return <div className={cmpClass} onClick={() => props.onClick()}>
        <div className={classMap.container + ' container'}>
            <a onClick={() => navigate('/catalog')}>Produkty</a>
            {/*<a onClick={() => navigate('/welcome')}>Novinky</a>*/}
            <a onClick={() => navigate('/wiki/obchodni-oddeleni')}>Kontakty</a>
            <a onClick={() => navigate('/machine-request')}>Poptávka</a>
        </div>
        <div className={classMap.divider + ' container'}>
            <hr />
        </div>
        <div className={classMap.container + ' container'}>
            <a onClick={() => navigate('/profile')}>Moje objednávky</a>
            <a onClick={() => navigate('/profile')}>Profil uživatele</a>
            <a onClick={() => {
                api.user.logout()
                    .then(() => queryClient.invalidateQueries({queryKey: ['user']}));
            }}>
                Odhlásit se
            </a>
        </div>
    </div>;
};

const cmpClass = 'main-menu';

const classMap = utils.generateClassMap(cmpClass, {
    container: 'container',
    divider: 'divider',
});
