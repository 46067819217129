import utils from 'utils';

import {Tabs, Tab} from '@mui/material';
import {useContext, useState, useEffect} from 'react';

import {ProductSearch} from '@components/export';
import appContext from '@/appContext';

export const CatalogMenu = (props: {
    options: {name: string, label: string}[],
}) => {
    const {filters, setFilters, setMachine} = useContext(appContext).productsContext;
    const [currentTab, setCurrentTab] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    const handle = {
        clickCategory(index: number) {
            setCurrentTab(index);
            setFilters({
                ...filters,
                offset: 0,
                attributes: [],
                // machineType: data.links[index].name,
                purpose: props.options[index].name,
                name: '',
            });
            setMachine({
                brand: '',
                model: '',
                purpose: '',
            });
        },
    };

    useEffect(() => {
        if (props.options[0].name && !isLoaded) {
            handle.clickCategory(
                filters.purpose ?
                    props.options.findIndex((option) => option.name === filters.purpose) :
                    0,
            );
            setIsLoaded(true);
        }
    }, [props.options]);

    return <div className={cmpClass}>
        <div className={classMap.container}>
            <div className={classMap.list}>
                <Tabs
                    value={currentTab}
                    onChange={(_e, value) => handle.clickCategory(value)}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {
                        props.options.map((link, index: number) => {
                            return <Tab
                                label={link.label}
                                id={link.name}
                                key={link.name}
                                className={classMap.item + (
                                    props.options[index].name ? ' ' + classMap.itemActive : ''
                                )}
                            />;
                        })
                    }
                </Tabs>
            </div>
            <ProductSearch />
        </div>
    </div>;
};

const cmpClass = 'catalog-menu';
const classMap = utils.generateClassMap(cmpClass, {
    container: 'container container',
    list: 'list',
    item: 'item',
    itemActive: 'item--active',
    inputSearch: 'link',
    iconSearch: 'icon iconfa-search',
});
