export default function convertFiltersToUrl(
    filters: any
): string {
    const { purpose, name, attributes } = filters;

    let path = `/catalog/p-/${encodeURIComponent(purpose)}`;

    if (name) {
        path += `/name-/${encodeURIComponent(name)}`;
    }

    path += `/a`;

    const params = new URLSearchParams();
    if (attributes) {
        attributes.forEach((attr: { name: string, value: string }) => {
            params.append(
                encodeURIComponent(attr.name),
                encodeURIComponent(attr.value)
            );
        });
    }

    return `${path}?${params.toString()}`;
}
