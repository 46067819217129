import utils from 'utils';

import {useNavigate} from 'react-router-dom';
import {useEffect, useContext} from 'react';
// import toast from 'react-hot-toast';

import {BigTitle, ProductCard, ProductsGridSort, UiBadge, UiButton} from '@components/export';
import {useLabel} from '@/hooks/export';

import {Skeleton} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import appContext from '@/appContext';

export const ProductsGrid = (props: {
    limit: number,
    isDemo: boolean,
    mode: 'catalog' | 'recommended' | 'alternative',
    alternativeTo?: string,
    purpose?: string,
    useFilters?: boolean,
    initFilters?: any,
}) => {
    const label = useLabel();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery('(min-width: 1024px)');

    const {filters, setFilters, searchRes, searchResQuery} = useContext(appContext).productsContext;

    const data = {
        productsCount: Array.isArray(searchRes?.pages) && searchRes.pages.at(-1).products.length,
    };

    const flags = {
        noProducts: !searchRes?.pages[0].products.length,
        showMore: data.productsCount >= props.limit,
        isScrollable:
            props.mode === 'alternative' &&
            (isDesktop ? data.productsCount > 4 : data.productsCount > 2),
    };

    const handle = {
        clickLoadMore: () => {
            return props.isDemo ?
                navigate('/catalog') :
                setFilters({
                    ...filters,
                    offset: filters.offset + filters.limit,
                });
        },
        cleanFilters() {
            setFilters({
                ...filters,
                attributes: [],
                name: '',
            });
        },
        selectFilter: (name: string, value: string) => {
            setFilters({
                ...filters,
                offset: 0,
                attributes: filters.attributes.filter((item) => {
                    return item.name !== name || item.value !== value;
                }),
            });
        },
    };

    useEffect(() => {
        const newFilters = {
            ...filters,
            offset: 0,
            limit: props.limit,
            recommended: props.mode === 'recommended',
            alternativeTo: props.mode === 'alternative' ? props.alternativeTo : '',
            attributes: [],
            sort: 'manufacturerAsc', // fixme: read from config
            useFilters: !!props.useFilters,
            ...props.initFilters,
        }

        if (props.purpose) {
            newFilters.purpose = props.purpose;
        }

        if (props.mode === 'recommended' || props.mode === 'alternative') {
            newFilters.name = '';
            newFilters.attributes = [];
            newFilters.purpose = '';
        }

        setFilters(newFilters);
    }, []);

    const wrapperClass = classMap.wrapper + (flags.isScrollable ? ' ' + classMap.wrapperScrollable : '');

    return <div className={cmpClass}>
        {
            props.mode === 'alternative' && !flags.noProducts ?
                <BigTitle title={label.alternativeProducts} dark={true} className={'product-page-products__title'}/> :
                <></>
        }
        {
            props.mode === 'catalog' ?
                <div className={classMap.controlBar}>
                    <div className={classMap.box}>
                        <span className={classMap.title}>
                            {label.searchResults}
                            <div className={classMap.selectedFilters}>
                                {
                                    filters.attributes !== undefined && filters.attributes.length ?

                                        filters.attributes.map((item, index) => {
                                            return <UiBadge
                                                title={getAttributeLabelByName(item.name) + ': ' + item.value}
                                                onClick={() => handle.selectFilter(item.name, item.value)}
                                                key={index}
                                            />;
                                        }) :
                                        <></>
                                }
                            </div>
                        </span>
                        {
                            (filters.attributes !== undefined && filters.attributes.length) || filters.name ?
                                <span
                                    onClick={() => handle.cleanFilters()}
                                    className={classMap.cleanFilters}
                                >
                                    <><i className={'iconfa-close'}></i>{label.cancelFilters}</>
                                </span> :
                                <></>
                        }
                    </div>
                    <ProductsGridSort/>
                </div> :
                <></>
        }
        {
            props.mode !== 'alternative' || !flags.noProducts ?
                <>
                    {
                        searchRes === undefined ?
                            <div className={wrapperClass}>
                                {
                                    [...Array(props.limit)].map((_e, i) => <Skeleton key={i} height={'100%'} variant={'rectangular'}/>)
                                }
                            </div> :
                            !flags.noProducts ?
                                <div className={wrapperClass}>
                                    {
                                        searchRes.pages.map((page, pageKey) => {
                                            return page.products
                                                .slice(0, props.limit)
                                                .map((product, key) => <ProductCard product={product} key={pageKey + key}/>);
                                            })
                                    }
                                </div> :
                                <div className={classMap.notFound}>
                                    <p>{label.offerFillMachineRequest}</p>
                                    <UiButton
                                        className={classMap.notFoundBtn}
                                        style={1}
                                        text={label.requestForProduct}
                                        onClick={() => navigate('/machine-request')}
                                    />
                                </div>
                    }
                    {
                        flags.noProducts || !flags.showMore || props.mode === 'alternative' ?
                            <></> :
                            <div className={classMap.loadMore}>
                                <UiButton
                                    style={1}
                                    text={label.showMore}
                                    onClick={() => handle.clickLoadMore()}
                                    loading={searchResQuery.isFetching}
                                />
                            </div>
                    }
                </> :
                <></>
        }
    </div>;
};

const cmpClass = 'products-grid';
const classMap = utils.generateClassMap(cmpClass, {
    box: 'box',
    cleanFilters: 'clean-filters',
    controlBar: 'control-bar',
    loadMore: 'load-more',
    sort: 'sort',
    title: 'title',
    wrapper: 'wrapper',
    wrapperScrollable: 'wrapper--scrollable',
    selectedFilters: 'selected-filters',
    notFound: 'not-found',
    notFoundBtn: 'not-found-btn',
});

const getAttributeLabelByName = (
    name: string,
) => {
    // fixme! dehardcode - get this data from api, but not directly
    //  - write it to context somewhere before - thus this method will not be needed

    // ? write to local state

    const attrLabelMap = {
        'Typ_pneu': 'Typ pneu',
        'Brand': 'Výrobce',
        'Prumer_rafku': 'Průměr ráfku',
        'Rozmer_pneu': 'Rozměr pneu',
        'Rim_pocet_platen': 'RIM / Počet pláten',
        'Pocet_platen': 'Počet pláten',
        'Pocet_platen_index': 'Počet pláten/Index',
        'Profil_kola': 'Profil kola',
        'Prumer_kola': 'Průměr kola',
        'Sirka_kola': 'Šířka kola',
        'Nosnost': 'Nosnost',
        'Sirka': 'Šířka',
        'Delka': 'Délka',
        'Trida_iso': 'Třída ISO',
        'Tloustka': 'Tloušťka',
    }

    return attrLabelMap[name];
}
