import utils from 'utils';

// todo: unify getting data and/or status

export const api = {
    user: {
        login: (creds) => {
            return utils.post('web/user/login', creds);
        },
        current: () => {
            return utils.fetch('web/user/current', false);
        },
        logout: () => {
            return utils.post('web/user/logout', {});
        },
        register: (fields) => {
            return utils.post('web/user/register', {form: fields});
        },
        forgotPass: (email) => {
            return utils.post('web/user/password/forgot', {email});
        },
        resetPass: (data) => {
            return utils.post('web/user/password/reset', data);
        },
        checkToken: (token) => {
            return utils.fetch('web/user/password/reset?token=' + token, false);
        },
        getAddresses: () => {
            return utils.fetch('web/user/address');
        },
        saveAddress: (data) => {
            return utils.post('web/user/address', data);
        },
        deleteAddress: (id: string) => {
            return utils.post('web/user/address/' + id + '/delete', {});
        },
        deleteContact: (id: string) => {
            return utils.post('web/user/contact/' + id + '/delete', {});
        },
        getContacts: () => {
            return utils.fetch('web/user/contact');
        },
        saveContact: (data) => {
            return utils.post('web/user/contact', data);
        },
        getRegisterFormEnums: () => {
            return utils.fetch('web/user/registerEnum', false)
                .then((response) => response.data);
        },
    },
    products: {
        getSingle: (id: string) => {
            return utils.fetch('web/products/' + id);
        },
        getMany: (data: any) => {
            return utils.post('web/products/search', data)
                .then((response) => response.data);
        },
        getByIds: (ids: string[]) => {
            return utils.post('web/products/get', {ids})
                .then((response) => response.data);
        },
    },
    filters: {
        index: (data: any) => {
            return utils.post('web/filters/index', data)
                .then((response) => response.data);
        },
        machines: (machine) => {
            return utils.post('web/filters/machines', machine)
                .then((response) => response.data);
        },
    },
    machine: {
        attributes: (fields: any) => {
            return utils.post('web/machine/attributes', fields)
                .then((response) => response.data);
        },
        request: (data: FormData) => {
            return utils.post(
                'web/machine/request',
                data,
                {'Content-Type': 'multipart/form-data'},
            )
                .then((response) => response.data);
        },
    },
    cart: {
        get: () => {
            return utils.fetch('web/cart', false)
                .then((response) => response.data);
        },
        update: (data) => {
            return utils.post('web/cart/update', data);
        },
        clean: () => {
            return utils.post('web/cart/clean', {});
        },
        updateNote: (data) => {
            return utils.post('web/cart/updateNote', data);
        },
        submit: (data) => {
            return utils.post('web/order/create', data);
        },
        sendAdditionalInfo: (orderId: string, additionalInfo: any) => {
            return utils.post('web/order/'+ orderId +'/additionalInfo', {additionalInfo});
        },
    },
    orders: {
        get: (data) => {
            if (data?.id) {
                return utils.fetch('web/order/' + data.id, false)
                    .then((response) => response.data);
            }

            return utils.fetch(`web/order/get?limit=${data.limit}&offset=${data.offset}`, false)
                .then((response) => response.data);
        },
        count: () => {
            return utils.fetch('web/order/get/count', false)
                .then((response) => response.data);
        },
    },
    invoices: {
        get: (data) => {
            if (data?.id) {
                return utils.fetch('web/invoice/' + data.id, false)
                    .then((response) => response.data);
            }

            return utils.fetch(`web/invoice/get?limit=${data.limit}&offset=${data.offset}`, false)
                .then((response) => response.data);
        },
        count: () => {
            return utils.fetch('web/invoice/get/count', false)
                .then((response) => response.data);
        },
    },
    serviceOrders: {
        get: (data) => {
            if (data?.id) {
                return utils.fetch('web/service-orders/' + data.id, false)
                    .then((response) => response.data);
            }

            return utils.fetch(`web/service-orders/get?limit=${data.limit}&offset=${data.offset}`, false)
                .then((response) => response.data);
        },
        count: () => {
            return utils.fetch('web/service-orders/get/count', false)
                .then((response) => response.data);
        },
    },
    catalog: {
        getPurposeList: () => {
            return utils.fetch('web/catalog/purposeList', false)
                .then((response) => response.data);
        },
    },
    wiki: {
        getPage: (id) => {
            return utils.fetch('web/wiki/' + id, false)
                .then((response) => response.data);
        },
    },
    company: {
        checkIco: (ico) => {
            return utils.fetch('web/company/checkIco/' + ico, false)
                .then((response) => response.data);
        },
    },
};

export {useApiQuery} from './useApiQuery.ts';
export {useApiInfiniteQuery} from './useApiInfiniteQuery.ts';
