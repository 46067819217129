import utils from 'utils';
import {api} from '@/api';

import {useQueryClient} from '@tanstack/react-query';

import {UiCheckbox, UiSelect} from '@components/export';
import {useEshopConfig, useCart} from '@/hooks/export';

import {TextField} from '@mui/material';

export const CartContactForm = (props: {
    onChange: (data: any) => void
    fields: any // todo: any are you okay
    showErrors: boolean,
}) => {
    const {saved} = useCart();
    const {layout} = useEshopConfig().cartContactForm;
    const queryClient = useQueryClient();

    return <div className={cmpClass}>
        {
            layout.map((group, groupI) => {
                return <div className={classMap.group} key={groupI}>
                    <p className={classMap.title}>
                        {group.title}
                    </p>

                    <div className={classMap.grid}>
                        {
                            group.grid.map((item, itemI) => {
                                return <div className={classMap.gridItem} key={itemI}>
                                    <div className={classMap[item.type]}>
                                        {
                                            getGridItemJSX(
                                                item,
                                                props.onChange,
                                                props.fields,
                                                saved,
                                                queryClient,
                                                props.showErrors,
                                            )
                                        }
                                    </div>
                                </div>;
                            })
                        }
                    </div>
                </div>;
            })
        }
    </div>;
};

// todo: move to new component?
const getGridItemJSX = (item, onChange, fields, saved, queryClient, showErrors) => {
    let options = [{value: '', label: ''}];

    switch (item.type) {
        case 'inputText':
            return <TextField
                label={item.label}
                size={'small'}
                value={fields[item.name]}
                fullWidth
                onChange={(e) => {
                    onChange({
                        name: item.name,
                        value: e.target.value,
                    });
                }}
                required={true}
                error={!fields[item.name] && showErrors}
            />;
        case 'select':
            // fixme: refactor ifs

            if (item.name === 'savedAddress' && saved.execAddresses) {
                options = saved.execAddresses.map((address) => {
                    return {
                        value: address.id,
                        label: address.execStreet + ', ' + address.execPostalCode + ' ' + address.execCity,
                    };
                });
            }

            if (item.name === 'savedContact' && saved.contacts) {
                options = saved.contacts.map((contact) => {
                    return {
                        value: contact.id,
                        label: contact.contactFirstName + ' ' + contact.contactLastName,
                    };
                });
            }

            return <UiSelect
                name={item.name}
                label={item.label}
                value={fields[item.name] || ''}
                onChange={(value) => {
                    onChange({name: item.name, value: value});

                    if (item.name === 'savedAddress') {
                        saved.execAddresses
                            .filter((item) => item.id === value)
                            .map((address) => {
                                ['execCity', 'execCompany', 'execPostalCode', 'execStreet']
                                    .map((field) => {
                                        onChange({name: field, value: address[field]});
                                    });
                            });
                    }

                    if (item.name === 'savedContact') {
                        saved.contacts
                            .filter((item) => item.id === value)
                            .map((contact) => {
                                ['contactFirstName', 'contactLastName', 'contactEmail', 'contactPhone']
                                    .map((field) => {
                                        onChange({name: field, value: contact[field]});
                                    });
                            });
                    }
                }}
                itemAction={{
                    iconClass: 'iconfa-close',
                    onClick: (value) => {
                        if (item.name === 'savedAddress') {
                            api.user.deleteAddress(value.toString())
                                .then(() => {
                                    queryClient.invalidateQueries({queryKey: ['savedAddresses']});
                                });
                        }

                        if (item.name === 'savedContact') {
                            api.user.deleteContact(value.toString())
                                .then(() => {
                                    queryClient.invalidateQueries({queryKey: ['savedContacts']});
                                });
                        }
                    },
                }}
                options={options}
                style={2}
            />;
        case 'text':
            return <p>{fields[item.name]}</p>;
        case 'checkbox':
            return <UiCheckbox
                onChange={(checked) => {
                    onChange({
                        name: item.name,
                        value: checked,
                    });
                }}
                label={item.label}
                defaultChecked={fields[item.name]}
            />;
        default:
            return <></>;
    }
};

const cmpClass = 'cart-contact-form';
const classMap = utils.generateClassMap(cmpClass, {
    checkbox: 'checkbox',
    grid: 'grid',
    gridItem: 'grid-item',
    group: 'group',
    inputText: 'input-text',
    select: 'select',
    text: 'text',
    title: 'title',
});
