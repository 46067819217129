import {useState} from 'react';
import {TextField} from '@mui/material';
import toast from 'react-hot-toast';
import {Footer, Header, BigTitle, UiButton} from '@components/export';
import {useLabel} from '@/hooks/useLabel';
import utils from 'utils';
import {useNavigate} from 'react-router-dom';

export const ChangePasswordPage = () => {
    const label = useLabel();
    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        oldPassword: '',
        newPassword: '',
        newPasswordRepeat: '',
    });

    const [passwordError, setPasswordError] = useState('');
    const [passwordRepeatError, setPasswordRepeatError] = useState('');

    /**
     * postPromise
     * @returns {Promise<unknown>}
     */
    const postPromise = async () => {
        const response = await utils.post('web/user/password/change', userData);
        if (response.status !== true) {
            console.error('postPromise: ', response);
            throw new Error(response.message);
        }

        setUserData({
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',
        });

        return response;
    };

    /**
     * handleChange
     */
    const handleChange = () => {
        const callFunction = postPromise();
        toast.promise(callFunction, {
            loading: label.saving,
            error: (err) => {
                return label.saveError + ': ' + (label[err.message] ?? err.message);
            },
            success: label.saved,
        }, {
            loading: {
                duration: 20000,
            },
        });
    };

    /**
     * handleCheckPasswordRepeat
     */
    const handleCheckPasswordRepeat = () => {
        if (userData.newPasswordRepeat.length > 0 && userData.newPassword !== userData.newPasswordRepeat) {
            setPasswordRepeatError(label.passwordsDoNotMatch);
        } else {
            setPasswordRepeatError('');
        }
    };

    /**
     * handleCheckPasswordRequirements
     */
    const handleCheckPasswordRequirements = () => {
        if (userData.newPassword.length < 8) {
            setPasswordError(label.passwordTooShort);
            // check if password has at least one number
        } else if (!/\d/.test(userData.newPassword)) {
            setPasswordError(label.passwordMustContainNumber);
            // check if password has at least one uppercase letter
        } else if (!/[A-Z]/.test(userData.newPassword)) {
            setPasswordError(label.passwordMustContainUppercase);
            // check if password has at least one lowercase letter
        } else {
            setPasswordError('');
        }
    };

    return <>
        <Header />

        <div className={'page-content user-settings-page'}>
            <div className={cmpClass + ' container'}>
                <div className={classMap.head}>
                    <span className={classMap.bread}>
                        <span>{label.profile + ' > '}</span>
                        <span>{label.userSettings}</span>
                    </span>
                    <div className={classMap.titleBox}>
                        <BigTitle title={label.userSettings} dark={true} className={classMap.bigTitle} />
                    </div>
                </div>

                <div className={classMap.card}>
                    <div className={classMap.form}>
                        <TextField
                            label={label.oldPassword}
                            size={'small'}
                            fullWidth
                            value={userData.oldPassword}
                            onChange={(e) => setUserData({
                                ...userData,
                                oldPassword: e.target.value,
                            })}
                            type='password'
                        />
                        <TextField
                            label={label.newPassword}
                            size={'small'}
                            fullWidth
                            value={userData.newPassword}
                            onChange={(e) => setUserData({
                                ...userData,
                                newPassword: e.target.value,
                            })}
                            inputProps={{
                                onBlur: () => {
                                    handleCheckPasswordRepeat();
                                    handleCheckPasswordRequirements();
                                },
                            }}
                            type='password'
                            error={passwordError.length > 0}
                            helperText={passwordError}
                        />
                        <div />
                        <TextField
                            label={label.newPasswordRepeat}
                            size={'small'}
                            fullWidth
                            value={userData.newPasswordRepeat}
                            onChange={(e) => setUserData({
                                ...userData,
                                newPasswordRepeat: e.target.value,
                            })}
                            type='password'
                            inputProps={{
                                onBlur: () => handleCheckPasswordRepeat(),
                            }}
                            error={passwordRepeatError.length > 0}
                            helperText={passwordRepeatError}
                        />
                    </div>
                    <div className={classMap.formButtons}>
                        <UiButton style={2} text={label.back} onClick={() => navigate('/profile')} />
                        <UiButton style={1} text={label.save} onClick={handleChange} />
                    </div>
                </div>
            </div>

        </div>

        <Footer />
    </>;
};

const cmpClass = 'user-settings';

const classMap = utils.generateClassMap(cmpClass, {
    bigTitle: 'big-title',
    card: 'card',
    head: 'head',
    list: 'list',
    listItem: 'list-item',
    listTitle: 'list-title',
    bread: 'bread',
    form: 'form',
    formButtons: 'form-buttons',
});
