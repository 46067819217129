import {api, useApiQuery} from '@/api';

import {useState} from 'react';

export const useCart = (props?: {
    enabled?: boolean,
}) => {
    const query = useApiQuery('getCart', {}, !props?.enabled);

    const cart : void | apiCart = query.data;

    const [productOptionsImages, setProductOptionsImages] = useState([]); // MachineRequestAttachment type

    const [contactForm, setContactForm] = useState({
        contactEmail: '',
        contactFirstName: '',
        contactLastName: '',
        contactPhone: '',
        doSaveAddress: false,
        doSaveContact: false,
        execCity: '',
        execCompany: '',
        execPostalCode: '',
        execStreet: '',
        info2: '',
        info: 'Fakturační firma podle pobočky uživatele',
        invoiceCity: '',
        invoiceCompany: '',
        invoicePostalCode: '',
        invoiceStreet: '',
    });

    const addProductOptionsImage = (productId: string, attachment: machineRequestAttachment) => {
        if (!Array.isArray(productOptionsImages[productId])) {
            productOptionsImages[productId] = [];
        }

        productOptionsImages[productId].push(attachment);

        setProductOptionsImages({...productOptionsImages});
    };

    return {
        query,
        cart,
        updateItem,
        updateNote,
        clean: () => api.cart.clean(),
        submit: async () => {
            const productOptionsImagesBase64 = {};

            for (const [key, values] of Object.entries(productOptionsImages)) {
                productOptionsImagesBase64[key] = await Promise.all(
                    values.map(async (item) => {
                        const base64File = await toBase64(item.file);
                        return {
                            ...item,
                            file: base64File,
                        };
                    })
                );
            }

            return api.cart.submit({
                products: (cart as unknown as apiCart).list, // fixme - temp fix
                contact: {
                    email: contactForm.contactEmail,
                    firstName: contactForm.contactFirstName,
                    lastName: contactForm.contactLastName,
                    phone: contactForm.contactPhone,
                },
                invoiceAddress: {
                    city: contactForm.invoiceCity,
                    company: contactForm.invoiceCompany,
                    postalCode: contactForm.invoicePostalCode,
                    street: contactForm.invoiceStreet,
                },
                execAddress: {
                    city: contactForm.execCity,
                    company: contactForm.execCompany,
                    postalCode: contactForm.execPostalCode,
                    street: contactForm.execStreet,
                },
                productOptionsImages: productOptionsImagesBase64,
            })
        },
        saved: {
            execAddresses: useApiQuery('getSavedAddresses', {}, !props?.enabled).data, // todo: add types
            contacts: useApiQuery('getSavedContacts', {}, !props?.enabled).data,
        },
        contactForm,
        setContactForm,
        productOptionsImages,
        setProductOptionsImages,
        addProductOptionsImage,
    };
};

const updateItem = (
    id: string,
    qty: number,
    additionalServices: [additionalService],
    additionalInfo?: {
        manufacturerNum: string,
        internalNum: string,
        note: string,
        execDate: string, // todo: check date format?
    },
) => {
    // todo: clear execDate if services total qty = 0

    return api.cart.update({
        productId: id,
        qty: qty,
        additionalServices: additionalServices,
        additionalInfo: additionalInfo || {},
    });
};

const updateNote = (note: string) => {
    return api.cart.updateNote({
        note,
    });
}

const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
};
