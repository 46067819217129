import {api} from '@/api';
import utils from 'utils';

import {useContext, useState, useEffect, useRef} from 'react';
import toast from 'react-hot-toast';

import {useLabel} from '@/hooks/export';
import {FilterListItem, FilterMachineSelect} from '@components/export';

import appContext from '@/appContext';

export const FilterList = (props: {
    purpose: number | string,
    filters: productsContext['filters'],
    setFilters: any, // fixme: any are you oki
}) => {
    const label = useLabel();

    const {filters, setFilters, machine, setMachine, searchRes, searchResQuery} = useContext(appContext).productsContext;
    const [list, setList] = useState({});

    const timeoutRef = useRef(null);

    const apiFilters = searchRes?.pages[0].filters;

    // todo: move to config
    const filtersOrder = [
        'Typ_pneu',
        'Brand',
        'Prumer_rafku',
        'Rozmer_pneu',
        'Rim_pocet_platen',
        'Pocet_platen',
        'Pocet_platen_index',
        'Profil_kola',
        'Prumer_kola',
        'Sirka_kola',
        'Nosnost',
        'Sirka',
        'Delka',
        'Trida_iso',
        'Tloustka',
    ];

    // todo: move to config
    const requiredFilters = [
        'Prumer_kola',
        'Prumer_rafku',
        'Sirka_kola',
        'Typ_pneu',
    ];

    // todo: move to config
    const typesWithMachineSelect = [
        2,
    ];

    const toastId = {
        success: 'success',
        error: 'error',
        loading: 'loading',
    };

    const flags = {
        // convert props.purpose to number if string - sometimes it comes as string
        showMachineSelect: typesWithMachineSelect.includes(typeof props.purpose === 'string' ? parseInt(props.purpose) : props.purpose),
    };

    const handle = {
        searchFilter: (value: string, name: string) => {
            const clean = mapFilters(apiFilters, filters);
            list[name] = {
                label: clean[name].label,
                list: value !== '' ?
                    clean[name].list.filter((item) => {
                        // accept 11.00 and 11,00 as same
                        value = value.replace(',', '.');
                        const pattern = value.split(' ').map(char => `(?=.*${char})`).join('');
                        const regex = new RegExp(`${pattern}`, 'i');

                        return regex.test(item.name);
                    }) :
                    clean[name].list,
            };

            setList({...list});
        },
        selectFilter: (isSelected: boolean, name: string, value: string) => {
            props.setFilters({
                ...props.filters,
                offset: 0,
                attributes: isSelected ?
                    [
                        ...props.filters.attributes || [],
                        {name, value},
                    ] :
                    props.filters.attributes.filter((item) => {
                        return item.name !== name || item.value !== value;
                    }),
            });
        },
        cleanFilters: () => {
            setFilters({
                ...filters,
                offset: 0,
                attributes: [],
            });
            setMachine({
                brand: '',
                model: '',
                purpose: '',
                // machineType: 'vzv', // todo: get current machineType
            });
        },
        selectMachine() {
            const toFilter = [];

            const toReturn = api.machine.attributes(machine)
                .then((attributes: [any]) => { // fixme: any
                    attributes.map((attribute) => {
                        if (attribute.purpose === machine.purpose || !machine.purpose) {
                            if (requiredFilters.includes(attribute.name)) {
                                toFilter.push({
                                    name: attribute.name,
                                    value: attribute.value,
                                });
                            } else if (list[attribute.name] !== undefined) {
                                list[attribute.name].list.map((item) => {
                                    if (item.name === attribute.value) {
                                        toFilter.push({
                                            name: attribute.name,
                                            value: attribute.value,
                                        });
                                    }
                                });
                            }
                        }
                    });

                    if (toFilter.length) {
                        setFilters({
                            ...filters,
                            offset: 0,
                            attributes: toFilter.filter((item, index, self) =>
                                // only unique pairs
                                index === self.findIndex((t) => (
                                    t.name === item.name && t.value === item.value
                                )),
                            ),
                        });
                    } else {
                        setMachine({
                            ...machine,
                            purpose: '',
                        });

                        toast.error(label.noAttrsSelected, {id: toastId.error});
                    }

                    return attributes;
                });

            return toReturn;
        },
    };

    if (searchResQuery) {
        if (searchResQuery.isFetching) {
            toast.remove(toastId.success);
            toast.loading(label.loading + '...', {id: toastId.loading});
        }

        if (searchResQuery.isFetched) {
            toast.remove(toastId.loading);
            toast.success(label.resultsIsUpdated, {id: toastId.success});
        }

        if (searchResQuery.fetchStatus === 'idle') {
            utils.doWithTimeout(() => {
                toast.dismiss(toastId.loading);
                toast.dismiss(toastId.success);
                toast.dismiss(toastId.error);
            }, 2000, timeoutRef);
        }
    }

    useEffect(() => {
        if (apiFilters !== undefined) {
            setList(mapFilters(apiFilters, props.filters));
        }
    }, [apiFilters, props.filters]);

    return <div className={cmpClass}>
        {
            flags.showMachineSelect ?
                <FilterMachineSelect handle={handle}/> :
                <></>
        }
        {
            Object.entries(list)
                .sort((a, b) => {
                    return filtersOrder.indexOf(a[0]) < filtersOrder.indexOf(b[0]) ? -1 : 1;
                })
                .map((filter, index) => {
                    return <FilterListItem
                        filter={{
                            ...(filter[1] as object),
                            name: filter[0],
                        }}
                        handle={handle}
                        key={index}
                    />;
                })
        }
    </div>;
};

/**
 * groups attrs returned by backend
 *
 * @todo: define types - keep comment there
 */
const mapFilters = (raw: [apiFilterOption] | void, state: any) => {
    const list = {};
    const filters = {};

    if (!Array.isArray(raw)) {
        return [];
    }

    raw.map((item) => {
        if (list[item.name] === undefined) {
            list[item.name] = {
                label: item.label,
                list: [],
            };
        }

        list[item.name].list.push(item);
    });

    Object.entries(list).map((filterGroup: [string, {label: string, list: [apiFilterOption]}]) => {
        filters[filterGroup[0]] = {
            label: filterGroup[1].label,
            list: filterGroup[1].list
                .map((filter) => {
                    let isChecked = false;
                    const qty = +filter.qty;

                    if (state.attributes) {
                        state.attributes.map((item) => {
                            if (item.name === filterGroup[0] && item.value === filter.value) {
                                isChecked = true;
                            }
                        });
                    }

                    return {
                        label: filter.value,
                        name: filter.value,
                        checked: isChecked,
                        qty: qty,
                    };
                })
                .sort((a, b) => a.name > b.name ? 1 : -1),
        };
    });

    return filters;
};

const cmpClass = 'filter-list';
