import {useQuery} from '@tanstack/react-query';

import {api} from '@/api/index.ts';

export const useApiQuery = (
    name: string,
    data: any,
    disabled?: boolean,
) => {
    let queryKey = [];
    let queryFn: () => void;

    switch (name) {
        case 'getProduct': {
            queryKey = ['product', data.id];
            queryFn = () => api.products.getSingle(data.id);

            break;
        }
        case 'getFilters': {
            const params = {
                attributes: data.filters?.attributes,
                purpose: data.filters?.purpose,
            };

            queryKey = ['filters', params];
            queryFn = () => api.filters.index(params);

            break;
        }
        case 'getCurrentUser': {
            queryKey = ['user'];
            queryFn = () => api.user.current();

            break;
        }
        case 'getFiltersMachines': {
            queryKey = ['filtersMachines', data.brand];
            queryFn = () => api.filters.machines({
                brand: data.brand,
            });

            break;
        }
        case 'getCart': {
            queryKey = ['cart'];
            queryFn = () => api.cart.get();

            break;
        }
        case 'getProducts': {
            queryKey = ['getProducts', data.ids];
            queryFn = () => api.products.getByIds(data.ids);

            break;
        }
        case 'getOrder': {
            const params = {
                id: data.id,
            };
            queryKey = ['order', params];
            queryFn = () => api.orders.get(params);

            break;
        }
        case 'getOrders': {
            const params = {
                limit: data.limit,
                offset: data.offset,
            };
            queryKey = ['orders', params];
            queryFn = () => api.orders.get(params);

            break;
        }
        case 'getInvoices': {
            const params = {
                limit: data.limit,
                offset: data.offset,
            };
            queryKey = ['invoices', params];
            queryFn = () => api.invoices.get(params);

            break;
        }
        case 'getServiceOrders': {
            const params = {
                limit: data.limit,
                offset: data.offset,
            };
            queryKey = ['serviceOrders', params];
            queryFn = () => api.serviceOrders.get(params);

            break;
        }
        case 'countOrders': {
            queryKey = ['ordersCnt'];
            queryFn = () => api.orders.count();

            break;
        }
        case 'countInvoices': {
            queryKey = ['invoicesCnt'];
            queryFn = () => api.invoices.count();

            break;
        }
        case 'countServiceOrders': {
            queryKey = ['serviceOrdersCnt'];
            queryFn = () => api.serviceOrders.count();

            break;
        }
        case 'getSavedAddresses': {
            queryKey = ['savedAddresses'];
            queryFn = () => api.user.getAddresses();

            break;
        }
        case 'getSavedContacts': {
            queryKey = ['savedContacts'];
            queryFn = () => api.user.getContacts();

            break;
        }
        case 'getPurposeList': {
            queryKey = ['purposeList'];
            queryFn = () => api.catalog.getPurposeList();

            break;
        }
        case 'getRegisterFormEnums': {
            queryKey = ['positionList'];
            queryFn = () => api.user.getRegisterFormEnums();

            break;
        }
        case 'getWiki': {
            queryKey = ['wiki', data.id];
            queryFn = () => api.wiki.getPage(data.id);

            break;
        }
        case 'updateNote': {
            queryKey = ['updateNote'];
            queryFn = () => api.cart.updateNote(data);

            break;
        }
    }

    return useQuery({
        queryKey,
        queryFn,
        refetchOnWindowFocus: false,
        enabled: !disabled,
    });
};
